.page h1, .page h2, .page p {
    @apply w-full sm:w-10/12 md:w-[550px];
}

h1 {
    @apply text-2xl font-bold;
}

h2 {
    @apply text-xl font-bold mt-2;
}

h3 {
    @apply text-lg font-bold;
}

p {
    @apply text-justify py-2;
}