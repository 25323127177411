.proposal p {
    @apply text-justify py-2;
}

.proposal p:first-child {
    @apply pt-0 text-lg;
}

.proposal a {
    @apply text-blue-500;
}

.proposal ul {
    @apply list-disc list-inside;
}