#root>div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.spacer {
    flex: 1;
}

.footer {
    clear: both;
    background-color: #bd94eb59 !important;
    padding: 2.5em 0 3em !important;
}

.masthead {
    min-height: 70vh;
}

.masthead h1 {
    color: #6d21d0 !important;
}

.masthead h1, .masthead h2 {
    font-size: 2.75em !important;
    margin: 0 !important;
    padding: 0 !important;
}

i.inverted.bordered.icon, i.inverted.circular.icon {
    background-color: #6d21d0 !important;
}

.article {
    margin: 2em;
}

blockquote {
    border-left: 0.4em solid lightgrey;
    padding: 0 1.5em;
    border-radius: 0.3em;
    margin-left: 2em;
}

.ql-editor ol, .ql-editor ul {
    padding-bottom: 1em;
}

h2 {
    font-size: 1.5em;
}

.card a {
    display: block;
}

.card-title h3 {
    color: #000000c9;
    overflow: hidden;
    position: relative;
    display: inline;
}

a.btn:hover {
    color: #ffffff;
}

/* .proposals a:hover h3 {
    opacity: 1;
}

.proposals a h3::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: left;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #6d21d0;
    transition: ease-in-out 0.2s;
}

.proposals a:hover h3::after, .proposals a:focus h3::after {
    opacity: 1;
    transform: scale(1);
} */

.card a h3 {
    background: 
      linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(to right, rgb(120, 0, 184), rgb(161, 57, 196), rgb(165, 84, 194));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size ease-in-out 300ms;
  }
  
  .card a:hover h3 ,
  .card a:focus h3 {
    background-size: 0 0.1em, 100% 0.1em;
    cursor: pointer;
  }

.ui.label>a {
    opacity: 0.75;
}

/* .proposals div {
    margin-bottom: 0.2em;
} */

.ui.vote-button:disabled {
    opacity: 1 !important;
}

.ui.primary.button {
    background-color: #6d21d0;
    transition: ease-in-out 0.2s;
}

.ui.primary.button:hover {
    background-color: #8c22e6;
    transition: ease-in-out 0.2s;
}

/* Navigation */

.ui.top.attached.menu {
    z-index: 100;
}

.logo {
    color: #6d21d0;
    width: 3em;
    display: flex;
    margin-top: 0.05em;
}

.logo::before {
    content: "speeqr";
    display: block;
    opacity: 0;
    transition: ease-in-out .5s;
    float: left;
}

.logo span {
    transform: rotate(-90deg);
    display: block;
    font-size: 2.5em;
    font-family: serif;
    opacity: 1;
    transition: ease-in-out .5s;
    margin-left: -1.1em;
    margin-top: 0.05em;
}

.logo:hover {
    color: #8c22e6;
    transition: ease-in-out .5s;
    display: block;
    opacity: 1;
}

.logo:hover span {
    opacity: 0;
    transform: rotate(0deg);
    font-size: 1em;
    transition: ease-in-out .5s;
    float: left;
    margin-left: -2.9em;
}

.logo:hover:before {
    content: "speeqr";
    transition: ease-in-out .5s;
    display: block;
    opacity: 1;
}

.logo-footer {
    color: #ababab80;
    width: 3em;
    display: flex;
    margin-top: -1.75em;
}

.logo-footer span {
    transform: rotate(-90deg);
    font-size: 6em;
    font-family: serif;
    opacity: 1;
    margin: 0 0 -0.85em 0.35em;
    z-index: -1;
}

input::placeholder {
    font-weight: bold;
    opacity: 0.2;
    color: #000000 !important;
}

/* #modal .grid {
    margin-left: 0;
    margin-right: 0;
} */

/* Transitions */
.hide-up .masthead {
    opacity: 0;
    height: 0vh;
    min-height: 0vh;
    transform: translateY(-1em);
    transition: ease-in-out 0.2s;
}

/* mobile settings */
@media only screen and (max-width: 600px) {
    .article {
        margin: 2em 0.2em;
    }

    .ui.grid .stackable.row .ui.vertical.divider, .ui.stackable.grid .ui.vertical.divider {
        display: none;
    }

    .footer .right {
        text-align: left !important;
    }
}